import { Link } from "react-router-dom";
import Header from './Header';

export default function Valeurs(){

    return(
        <>
        <Header/>
        <Block1 />
     
        </>

    );

}

function Block1(){
    return(
<>
<section id="values" className="values">

<div className="container" data-aos="fade-up">

  <header className="section-header">
  </header>

  <div className="row">

    <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
      <div className="box">
        <img src="assets/img/values-1.png" className="img-fluid" alt="" />
        <h3>Collaboration et esprit d'équipe</h3>
       
      </div>
    </div>

    <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
      <div className="box">
        <img src="assets/img/values-2.png" className="img-fluid" alt="" />
        <h3>assion pour la technologie et l'innovation</h3>
    
      </div>
    </div>

    <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div className="box">
        <img src="assets/img/values-3.png" className="img-fluid" alt="" />
        <h3>Engagement envers la qualité et l'exellence.</h3>
  
      </div>
    </div>
    <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div className="box">
        <img src="assets/img/features.png" className="img-fluid" alt="" />
        <h3>Intégrité et éthique proffesionnelle.</h3>
        
      </div>
    </div>
    <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div className="box">
        <img src="assets/img/hero-img.png" className="img-fluid" alt="" />
        <h3>Orienté vers les résultats.</h3>
     
      </div>
    </div>
    <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div className="box">
        <img src="assets/img/features-2.png" className="img-fluid" alt="" />
        <h3>Adaptabilité et Curiosité.</h3>
     
      </div>
    </div>

  </div>

</div>
<div className="but"> <Link to={"/Compétences"}><a href="" className="btn-get-started color-white">Mes compétences</a></Link>
 </div> 

</section>
</>
      
    );
}


